import React from 'react'
import { Link } from 'gatsby'
import * as images from 'images/home'
import Helmet from 'react-helmet'
import { heroKlantenservice } from 'images/hulp/klantenservice'
import Layout from '../../layouts'

import Checkmark from 'components/misc/checkmark'
const {
  logos: {
    woodwatch,
    petossi,
    storageShare,
    codaisseur,
  }
} = images

class PayrollPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      active: false,
      activeID: ''
    }

    this.setActive = this.setActive.bind(this)
  }

  setActive(event) {
    let id = event.target.id

    if(id === this.state.activeID) {
      id = ''
    }

    this.setState({
      active: !this.state.active,
      activeID: id
    })
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <main className="demo animated fadeInPage">

          <Helmet>
            <title>Payroll Informatie | Ontdek de voor- en nadelen | Employes</title>
            <meta name="description" content="Payroll, is dat iets voor mijn bedrijf? Alle voor- en nadelen. ✅ Bespaar direct op je kosten en risico's." />
            <meta itemprop="name" content="Payroll Informatie | Ontdek de voor- en nadelen | Employes" />
            <meta itemprop="description" content="Payroll, is dat iets voor mijn bedrijf? Alle voor- en nadelen. ✅ Bespaar direct op je kosten en risico's." />
            <meta itemprop="image" content="/static/meta-img.png" />
          </Helmet>

          <header className="header padding-l">
            <div className="container-md relative">
              <div className="grid yg align-middle margin-l-bottom center">


                <div className="col-12">
                  <div className="title margin-s-bottom">
                    <h5 className="eyebrow turq no-margin">Het alternatief Payroll</h5>
                    <h1>Payroll vs. salarisadministratie</h1>
                    <p>Employes biedt je een <strong>alternatief voor payroll</strong>, wij vinden namelijk dat je het beste zelf personeel in dienst kunt nemen. Dit bespaart kosten en zorgt voor een betere binding met je werknemers. Maar om ervoor te zorgen dat je toch goed het <Link to="/kennisbank/payroll-vs-salarisadministratie/" className="link mulberry">verschil tussen payroll en salarisadministratie</Link> kunt bepalen, leggen we hieronder uit wat payrolling is. Na het lezen van dit artikel kun je dan zelf een afgewogen keuze maken tussen het zelf in dienst nemen of payroll.</p>
                  </div>
                </div>

                <div className="col-12 margin-l-bottom">
                  <h2>Payroll definitie</h2>
                  <p className="margin-s-bottom">Het woord <Link to="/payroll/payroll-wat-is-dat/" className="link mulberry">payroll</Link> betekent vertaald vanuit het Engels letterlijk loonlijst, maar wordt hier in Nederland vooral gebruikt als benaming voor een bepaalde business. Namelijk de uitbesteding van de salarisadministratie en het werkgeverschap voor jouw medewerkers. Een payroll constructie houdt in dat je als werkgever nog altijd zelf je eigen personeel zoekt en de verantwoordelijkheid blijft houden voor de aansturing op de werkvloer, terwijl je zelf officieel niet meer de werkgever bent.</p>


                  <h3>Payroll constructie</h3>
                  <p className="margin-s-bottom">Bij payrolling staan werknemers dus niet meer op je eigen loonlijst maar op dat van het payroll bedrijf waar je het uitbesteedt. Hiermee vervalt dus het juridisch werkgeverschap voor jou. Deze constructie zorgt ervoor dat het payroll bedrijf de loonaangifte doet en de werknemers uitbetaald. Het payroll-bedrijf stuurt vervolgens een factuur naar het bedrijf. Verder biedt het payroll bedrijf vaak een <Link to="/payroll/payroll-online/" className="link mulberry">online portal</Link> of <Link to="/payroll/payroll-software/" className="link mulberry">payroll software</Link> voor hun klanten aan waarmee ze de loonstroken kunnen bekijk. De vraag is dan, waarom zou een bedrijf eventueel kiezen voor payroll?</p>



                  <h2>Voordelen payroll</h2>
                  <p className="margin-s-bottom">Het uitbesteden van de payroll brengt een aantal voordelen met zich mee. Het <Link to="/payroll/voordelen-payroll/" className="link mulberry">voordeel van payroll</Link> is dat het een aantal risico’s van het werkgeverschap wegneemt. Zo draait je bedrijf niet op voor de kosten bij ziekte of verzuim van een werknemer en heeft een werknemer de zekerheid dat zijn salaris wordt uitbetaald. Verder worden administratieve zaken overgenomen door het payroll bedrijf. Je blijft wel als ondernemer aansprakelijk voor de afdracht van de loonheffingen.</p>




                  <h2>Payroll kosten</h2>
                  <p className="margin-s-bottom">Omdat je bij payroll veel verplichtingen en verantwoordelijkheid uit handen geeft, betaal je hier uiteraard wel voor. Dit is een van de <Link to="/payroll/nadelen-van-payroll/" className="link mulberry">nadelen van payroll</Link>. <Link to="/payroll/wat-kost-payroll/" className="link mulberry">Wat payroll kost</Link> wordt bepaald aan de hand van het bruto-uurloon en een omrekenfactor, die payrollbedrijven hanteren.</p>

                  <p className="margin-s-bottom">Een payroll-bedrijf werkt met een omrekenfactor op het bruto-uurloon. De gemiddelde omrekenfactor van een payroll-bedrijf ligt op 1,67 hoewel er ook uitschieters naar boven zijn van 1,70 tot 1,75. Met andere woorden, wanneer een medewerker een bruto uurloon van 10,- verdient, betaal je 16,70 aan het payroll-bedrijf. De werkelijke loonkosten per uur bedragen maximaal 15,-. Ingeval er sprake is van jongere werknemers die nog niet deelnemen in het pensioenfonds is de omrekenfactor nog minder. Een op het eerste gezicht klein verschil. Maar wanneer je dit omrekent naar een totaal aantal uren per jaar gaat het om grote bedragen die je kunt besparen.</p>




                  <h3>Payroll vergelijken</h3>
                  <p className="margin-s-bottom">De laatste jaren zijn er meer en meer bedrijven ontstaan die <Link to="/payroll/diensten/" className="link mulberry">payroll diensten</Link> aanbieden. Om je een idee te geven deze bedrijven, hebben we een lijst gemaakt met de bedrijven en de verschillen met Employes beschreven.</p>
                  <Link to="/payroll/employes-vs-tentoo/" className="link mulberry">Employes vs. Tentoo</Link><br /><br />

                    <p className="seo-link">Payroll plaatsen:&nbsp;
                      <Link className="seo-link" to="/payroll-almelo/">Almelo </Link>,
                      <Link className="seo-link" to="/payroll-almere/">Almere </Link>,
                      <Link className="seo-link" to="/payroll-alphen-aan-den-rijn/">Alphen aan den Rijn </Link>,
                      <Link className="seo-link" to="/payroll-alkmaar/">Payroll Alkmaar </Link>,
                      <Link className="seo-link" to="/payroll-amersfoort/">Amersfoort </Link>,
                      <Link className="seo-link" to="/payroll-amstelveen/">Amstelveen </Link>,
                      <Link className="seo-link" to="/payroll-amsterdam/">Amsterdam </Link>,
                      <Link className="seo-link" to="/payroll-apeldoorn/">Apeldoorn </Link>,
                      <Link className="seo-link" to="/payroll-arnhem/">Arnhem </Link>,
                      <Link className="seo-link" to="/payroll-assen/">Assen </Link>,
                      <Link className="seo-link" to="/payroll-bergen-op-zoom/">Bergen op Zoom </Link>,
                      <Link className="seo-link" to="/payroll-breda/">Breda </Link>,
                      <Link className="seo-link" to="/payroll-capelle-aan-den-ijssel/">Capelle aan den IJssel </Link>,
                      <Link className="seo-link" to="/payroll-delft/">Delft </Link>,
                      <Link className="seo-link" to="/payroll-den-bosch/">Den Bosch </Link>,
                      <Link className="seo-link" to="/payroll-den-haag/">Den Haag </Link>,
                      <Link className="seo-link" to="/payroll-den-helder/">Den Helder </Link>,
                      <Link className="seo-link" to="/payroll-deventer/">Deventer </Link>,
                      <Link className="seo-link" to="/payroll-doetinchem/">Doetinchem </Link>,
                      <Link className="seo-link" to="/payroll-dordrecht/">Dordrecht </Link>,
                      <Link className="seo-link" to="/payroll-ede/">Ede </Link>,
                      <Link className="seo-link" to="/payroll-eindhoven/">Eindhoven </Link>,
                      <Link className="seo-link" to="/payroll-emmen/">Emmen </Link>,
                      <Link className="seo-link" to="/payroll-enschede/">Enschede </Link>,
                      <Link className="seo-link" to="/payroll-geleen/">Geleen </Link>,
                      <Link className="seo-link" to="/payroll-gouda/">Gouda </Link>,
                      <Link className="seo-link" to="/payroll-groningen/">Groningen </Link>,
                      <Link className="seo-link" to="/payroll-haarlem/">Haarlem </Link>,
                      <Link className="seo-link" to="/payroll-heerenveen/">Heerenveen </Link>,
                      <Link className="seo-link" to="/payroll-heerlen/">Heerlen </Link>,
                      <Link className="seo-link" to="/payroll-helmond/">Helmond </Link>,
                      <Link className="seo-link" to="/payroll-hengelo/">Hengelo </Link>,
                      <Link className="seo-link" to="/payroll-hilversum/">Hilversum </Link>,
                      <Link className="seo-link" to="/payroll-hoofddorp/">Hoofddorp </Link>,
                      <Link className="seo-link" to="/payroll-hoogeveen/">Hoogeveen </Link>,
                      <Link className="seo-link" to="/payroll-hoorn/">Hoorn </Link>,
                      <Link className="seo-link" to="/payroll-katwijk/">Katwijk </Link>,
                      <Link className="seo-link" to="/payroll-leeuwarden/">Leeuwarden </Link>,
                      <Link className="seo-link" to="/payroll-leiden/">Leiden </Link>,
                      <Link className="seo-link" to="/payroll-leidschendam/">Leidschendam </Link>,
                      <Link className="seo-link" to="/payroll-lelystad/">Lelystad </Link>,
                      <Link className="seo-link" to="/payroll-maastricht/">Maastricht </Link>,
                      <Link className="seo-link" to="/payroll-middelburg/">Middelburg </Link>,
                      <Link className="seo-link" to="/payroll-nieuwegein/">Nieuwegein </Link>,
                      <Link className="seo-link" to="/payroll-nijmegen/">Nijmegen </Link>,
                      <Link className="seo-link" to="/payroll-oss/">Oss </Link>,
                      <Link className="seo-link" to="/payroll-purmerend/">Purmerend </Link>,
                      <Link className="seo-link" to="/payroll-roermond/">Roermond </Link>,
                      <Link className="seo-link" to="/payroll-roosendaal/">Roosendaal </Link>,
                      <Link className="seo-link" to="/payroll-rotterdam/">Rotterdam </Link>,
                      <Link className="seo-link" to="/payroll-schiedam/">Schiedam </Link>,
                      <Link className="seo-link" to="/payroll-spijkenisse/">Spijkenisse </Link>,
                      <Link className="seo-link" to="/payroll-terneuzen/">Terneuzen </Link>,
                      <Link className="seo-link" to="/payroll-tilburg/">Tilburg </Link>,
                      <Link className="seo-link" to="/payroll-utrecht/">Utrecht </Link>,
                      <Link className="seo-link" to="/payroll-veenendaal/">Veenendaal </Link>,
                      <Link className="seo-link" to="/payroll-venlo/">Venlo </Link>,
                      <Link className="seo-link" to="/payroll-vlaardingen/">Vlaardingen </Link>,
                      <Link className="seo-link" to="/payroll-voorburg/">Voorburg </Link>,
                      <Link className="seo-link" to="/payroll-westland/">Westland </Link>,
                      <Link className="seo-link" to="/payroll-zaandam/">Zaandam </Link>,
                      <Link className="seo-link" to="/payroll-zeist/">Zeist </Link>,
                      <Link className="seo-link" to="/payroll-zoetermeer/">Zoetermeer </Link>,
                      <Link className="seo-link" to="/payroll-zwolle/">Zwolle </Link>
                    </p>
                </div>
              </div>
            </div>

          </header>

        </main>
      </Layout>
    )
  }
}

export default PayrollPage
